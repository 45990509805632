import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Link from '../UI/Link/Link';
import Icon from '../UI/Icon/Icon';
import styles from './ArticleNav.module.scss';

const ArticleNav = ({ prevUrl, nextUrl, title, link}) => {
    const [isVisible, setIsVisible] = useState(false);
    const className = classNames( styles.articleNav, {
        [styles.isVisible]: isVisible
    });

    function handleScroll( e ){
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        setIsVisible( scrollTop > 140 );
    }
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return (
        <nav className={className}>
            <div className={styles.navigation}>
                { prevUrl ?
                    <Link className={styles.navLink} to={prevUrl} aria-label="Previous article">
                        <Icon name="arrow-left"/>
                    </Link>

                    :

                    <span className={styles.navLinkDisabled} aria-label="Previous article">
                        <Icon name="arrow-left"/>
                    </span>
                }

                { nextUrl ?
                    <Link className={styles.navLink} to={nextUrl} aria-label="Next article">
                        <Icon name="arrow-right"/>
                    </Link>

                    :

                    <span className={styles.navLinkDisabled} aria-label="Next article">
                        <Icon name="arrow-right"/>
                    </span>
                }
            </div>
            

            <strong className={styles.title}>{title}</strong>
            
            {link &&
                <a className={styles.link} href={link} target="_blank" rel="noopener noreferrer">
                    <span className={styles.linkLabel}>Visit site</span>
                    <Icon className={styles.linkIcon} name="external-link"/>
                </a>
            }
            
        </nav>
    )
}

ArticleNav.propTypes = {

};

export default ArticleNav;

import React from 'react';
import AssetZenImage from '../AssetZenImage/AssetZenImage';

import styles from './GalleryImage.module.scss';

const GalleryImage = props => {
    return (
        <AssetZenImage {...props} className={styles.galleryImage}/>
    )
}

GalleryImage.propTypes = {

};

export default GalleryImage;

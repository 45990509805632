import React from 'react';
import { graphql } from 'gatsby';
import { themes } from "../context/ThemeContext";
import Layout from '../components/Layout';
import Section from '../components/Section/Section';
import Content, { HTMLContent } from '../components/Content';
import Cover from '../components/Cover/Cover';
import Hgroup from '../components/UI/Hgroup/Hgroup';
import Testimonial from '../components/UI/Testimonial/Testimonial';
import Article from '../components/Article/Article';
import { MasonryGrid, MasonryGridItem } from '../components/UI/MasonryGrid/MasonryGrid';
import GalleryImage from '../components/UI/GalleryImage/GalleryImage';
import NavList from '../components/UI/NavList/NavList';
import StatList from '../components/UI/StatList/StatList';
import WorkItem from '../components/WorkItem/WorkItem';
import ArticleNav from '../components/ArticleNav/ArticleNav';
import Fade from 'react-reveal/Fade';
import parseMarkdown from '../utils/parseMarkdown';

import type from '../styles/type.module.scss';

export const WorkItemPageTemplate = ({
    page,
    prev,
    next,
    related,
    contentComponent
}) => {
    const PageContent = contentComponent || Content;
    const roles = page.tags.length ? (
        <Fade bottom>
            <h2 className={type.h4}>Roles</h2>
            <NavList items={ page.tags.map( tag => (
                { link: `/work/?filter=${tag.value}`, label: tag.label }
            ))}/>
        </Fade>
    ) : null;

    return (
        <>
            <Cover image={page.image}>
                <Fade bottom>
                    <Hgroup lead={page.name} title={page.title} isReverse={true} isPrimary={true} />
                </Fade>
            </Cover>

            <ArticleNav prevUrl={prev} nextUrl={next} title={page.name} link={page.externalLink}/>

            <Section theme={themes.LIGHT} isCompact={true}>
                <Article aside={roles}>
                    {page.html &&
                        <Fade bottom><PageContent content={page.html} /></Fade>
                    }
                    
                    {page.stats && page.stats.length > 0 &&
                        <Fade bottom><StatList items={page.stats}/></Fade>
                    }
                </Article>

                {page.testimonial && page.testimonial.length > 0 &&
                    <Testimonial data={page.testimonial[0]} />
                }

                <MasonryGrid>
                    {page.gallery && page.gallery.map( id => {
                        return (
                            <MasonryGridItem key={id}>
                                 <Fade bottom>
                                    <GalleryImage id={id} width="588" quality="90"/>
                                </Fade>
                            </MasonryGridItem>
                        )
                    })}
                </MasonryGrid>
                
            </Section>

            {related.length > 0 &&
                <Section Tag="aside" theme={themes.LIGHT} isCompact={true} containerStyle="SECONDARY">
                    <h2 className={type.sectionHeading}>
                        Related projects
                    </h2>

                    <MasonryGrid>
                        {related.map( ({node}) => {
                            return (
                                <MasonryGridItem key={node.id}>
                                    <WorkItem 
                                        {...node.frontmatter}
                                        slug={node.fields.slug} />
                                </MasonryGridItem>
                            )
                        })}
                    </MasonryGrid>
                </Section>
            }
        </>
    )
};

const WorkItemPage = ({ data: { page, prev, next, related } }) => {
    return (
        <Layout 
            meta={page.frontmatter.meta || false}
            title={page.frontmatter.title || false}
            theme={themes.DARK}
        >
            <WorkItemPageTemplate
                page={parseMarkdown(page)}
                prev={prev && prev.fields.slug} 
                next={next && next.fields.slug} 
                related={ related.edges }
                contentComponent={HTMLContent}/>
        </Layout>
    )
};

export default WorkItemPage;

export const pageQuery = graphql`
  ## Query for WorkItemPage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query WorkItemPageTemplate($id: String!, $tags: [String], $prev: String!, $next: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      ...Testimonial
      frontmatter {
        name
        title
        image {
            id
            src {
                publicURL
                extension
            }
            fit
            position
        }
        externalLink
        stats {
            label
            value
        }
        gallery
        tags {
            value
            label
        }
      }
      html
    }
    prev: markdownRemark(fields: { slug: { eq: $prev } }) {
        id
        fields {
          slug
        }
    }
    next: markdownRemark(fields: { slug: { eq: $next } }) {
        id
        fields {
          slug
        }
    }
    related: allMarkdownRemark(
        limit: 4
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { 
            id: { ne: $id },
            frontmatter: { 
                template: {eq: "WorkItem"}
                isPublished: { ne: false }
                tags: {
                    elemMatch: { 
                        value: { in: $tags }
                    }
                }
            } 
        }
    ) {
        edges {
            node {
                id
                fields {
                    slug
                }
                frontmatter {
                    name
                    title
                    image {
                        id
                        src {
                            publicURL
                            extension
                        }
                        fit
                        position
                    }
                    tags {
                        value
                        label
                    }
                }
            }
        }
     }
  }
`

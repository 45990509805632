import React from 'react';
import { v4 } from 'uuid'; 
import { Grid, GridItem } from '../Grid/Grid';
import styles from './StatList.module.scss';

const StatList = ({ items }) => {
    return (
        <Grid className={styles.statList}>
            {items.map((item) =>
                <GridItem lg={6} key={v4()}>
                    <Stat {...item}/>
                </GridItem>
            )}
        </Grid>
    )
}

StatList.propTypes = {

};

const Stat = ({ label, value }) => {
    return (
        <dl className={styles.stat} key={v4()}>
            <dt className={styles.label}>{ label }</dt>
            <dd className={styles.value}>{ value }</dd>
        </dl>
    )
}

StatList.propTypes = {

};


export default StatList;
